import React, { useState, useEffect } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { deepOrange, deepPurple } from '@material-ui/core/colors';
import  { Redirect } from 'react-router-dom'
import api from '../api/crud';
import { WindowScroller } from 'react-virtualized';
//import {useForm} from 'react-hook-form';



const classes = makeStyles((theme) => ({
    paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: [theme.palette.secondary.main],
  
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    orange: {
      color: theme.palette.getContrastText(deepOrange[500]),
      backgroundColor: deepOrange[500],
    }
  }));

export default function Login() {
    const [usuario, setUsuario] = useState('');
    const [password, setPassword] = useState('');
  // ESTILOS DE ARRIBA
  // HOOK-FORM
  //const {register, handleSubmit} = useForm()
  // ESTADOS
  //const [usuario, setUsuario] = useState("ESTE ES");

  // SE EJECUTA AL INICIARSE LA PAGINA 
const onChangeUsuario = event => {
    setUsuario(event.target.value);
 };

const onChangePassword = event => {
    setPassword(event.target.value);
 };

 const enviar = event => {
     var datos={usuario:{usuario},password:{password}}
    api.buscarUsuario(datos).then(data => {
        if(data.data.statusCode===1000){
            window.localStorage.setItem('code', data.data.data.identificador);
            window.localStorage.setItem('idPDV', data.data.data.id);
            window.location.href = '#/inventario';
        }
        console.log(data)
       

    })
 };


  useEffect(() => {

  });


 // function buscarTelefono(data){
  //  api.buscarClave(data.telefono)
  //  .then(respuesta => {
   //   if(respuesta.data.status=="EXITO"){
   //     localStorage.setItem('user',respuesta.data.password)
   //     window.location.href='#registrar'
   //   }else{
    //    alert("USUARIO NO EXISTE ")
   //   }
    //    console.log(respuesta);
  //  })

  return (
    // <div>
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div style={{ alignItems: "center", marginTop: 1, display: 'flex', flexDirection: 'column', }} className={classes.paper}>
        <Avatar style={{ color: "white", background: "red", alignItems: "center" }} className={classes.orange}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
      </Typography>
        <form className={classes.form} noValidate>
          <TextField
            //error={this.state.errorCorreo}
            //helperText={this.state.helperCorreo}
            onChange={onChangeUsuario}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="usuario"
            label="Usuario"
            name="usuario"
            value={usuario}
            //value={this.state.email}
    
            autoFocus
          />
          <TextField
            //error={this.state.errorPassword}
            //helperText={this.state.helperPassword}
            onChange={onChangePassword}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
          />

          <Button
            onClick={enviar}
            fullWidth
            variant="contained"
            color="primary"
            //className={classes.submit}
          >
            Login
        </Button>
          <Grid container>

            <Grid item>
              <Link style={{ fontSize: "18px" }} href="#signup" variant="body2">
                {"Crear Cuenta"}
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={8}>
        {/* <Copyright /> */}
      </Box>
    </Container>

);
  }


