/* eslint-disable import/no-anonymous-default-export */
import axios from "axios";
// const API_URL = 'https://api.pontechucho.com/chuchoapi';
const API_URL = 'https://apipdv.abecode.com/pdv';

export default {

    // MUESTRA TODOS LOS REGISTRO 
    allInventario: function () {
        return axios.get(API_URL + "/allInventario")
    },
    buscarUsuario: function (datos) {
        return axios.put(API_URL + "/buscarUsuario", datos)
    },
    buscarIdentificador: function (datos) {
        return axios.put(API_URL + "/buscarIdentificador", datos)
    },
    agregarProducto: function (datos) {
        return axios.put(API_URL + "/agregarProducto", datos)
    },
    modificarProducto: function (datos) {
        return axios.put(API_URL + "/modificarProducto", datos)
    },    
    buscarProductoInventario: function (datos) {
        // console.log(datos)
        return axios.put(API_URL + "/buscarProductoInventario", datos)
    },
    restarInventario: function (datos) {
        // console.log(datos)
        return axios.put(API_URL + "/restarInventario", datos)
    },
    sumarInventario: function (datos) {
        console.log(datos.IdProducto)
        return axios.put(API_URL + "/sumarInventario", datos)
    },
    agregarenVenta: function (datos) {
        // console.log(datos)
        return axios.put(API_URL + "/agregarenVenta", datos)
    },
    allenVenta: function () {
        // console.log(datos)
        return axios.get(API_URL + "/allenVenta")
    },
    borrarenVenta: function (datos) {
        // console.log(datos)
        return axios.put(API_URL + "/borrarenVenta", datos)
    },
    aceptarVenta: function (datos) {
        // console.log(datos)
        return axios.put(API_URL + "/aceptarVenta", datos)
    },
    aceptarVendidos: function (datos) {
        // console.log(datos)
        return axios.put(API_URL + "/aceptarVendidos", datos)
    },
    eliminarenVenta: function (datos) {
        // console.log(datos)
        return axios.put(API_URL + "/eliminarenVenta",datos)
    },

    ventaDia: function (datos) {
        // console.log(datos)
        return axios.put(API_URL + "/ventaDia",datos)
    },

    productosVenta: function (datos) {
        // console.log(datos)
        return axios.put(API_URL + "/productosVenta",datos)
    },

    
    productosImprimir: function (datos) {
        // console.log(datos)
        return axios.put(API_URL + "/productosImprimir",datos)
    },
    buscarAnio: function (datos) {
        // console.log(datos)
        return axios.put(API_URL + "/buscarAnio",datos)
    },
    buscaraniomes: function (datos) {
        // console.log(datos)
        return axios.put(API_URL + "/buscaraniomes",datos)
    },
    buscaraniomesdia: function (datos) {
        // console.log(datos)
        return axios.put(API_URL + "/buscaraniomesdia",datos)
    },
    agregarLog: function (datos) {
        // console.log(datos)
        return axios.put(API_URL + "/agregarLog",datos)
    }

    // // BUSCA SI HAY UN TELEFONO REGISTRADO
    // buscarTelefono: function (id) {
    //     return axios.get(API_URL + "/TelefonoOne/" + id);

    // },
    // registrar: function (datos) {
    //     return axios.put(API_URL + "/registrar", datos)
    
    

    // }
};
