import React from 'react';
import '../styles/BarraDerecha.css';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import api from '../api/crud';








class Configuracion extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            inventario: [],
            ventas: [],
            config: [],
            seleccion: "",
            passAnterior: "",
            passNuevo: "",
            passConfirmar: "",
            helperPass: "",
            errorPass: false,
            helperNuevoPass: "",
            errorNuevoPAss: false,
            helperConfirmar: "",
            errorConfirmar: false,
            procesado: []


        };

        this.logout = this.logout.bind(this);






    }

    async componentDidMount() {

        let identificador={id:window.localStorage.getItem('code')};
        console.log(identificador)
        api.buscarIdentificador(identificador).then(data =>{
            if(data.data.statusCode!==1000){

                window.location.href = '/';
            }
            });



    }

    logout = () => {
        window.localStorage.removeItem('idPDV');
        window.localStorage.removeItem('code');
        window.location.href = '/';
        
    }



   

    _onChange = event => {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        });
    };

    render() {
        let componente = <p>Inicio</p>
        let texto = ""

    

       


        return (


            <Grid container style={{ backgroundColor: '#dddddd' }} >


                <Grid item xs={1} style={{ backgroundColor: '#dddddd' }}  ></Grid>
                <Grid item xs={8}>
                    {/* <Grid textAlign={'center'} xs={2} container style={{ backgroundColor: '#dddddd', height: '100vh' }} > </Grid> */}
                    <Grid style={{ backgroundColor: '#dddddd', height: '100%' }} >



                        <Grid style={{ fontStyle: "", backgroundColor: '#ffffff', width: '50%' }} >

                            {texto}

                            {componente}


                        </Grid>


                    </Grid>





                </Grid>
                <Grid item xs={1} style={{ backgroundColor: '#dddddd', height: '100vh' }}></Grid>
                <Grid item xs={2} style={{ backgroundColor: '#FFFFFF' }}>


                    <ButtonGroup orientation="vertical" size="large" color="primary" aria-label="large outlined primary button group" fullWidth>
                        <Button style={{ marginTop: '5px' }} variant="contained" color="primary" onClick={(e) => this.logout()}>Logout</Button >

                    </ButtonGroup>


                </Grid>



            </Grid>
        );
    }
}

export default Configuracion;